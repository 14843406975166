import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import MostPopularCards from "../components/MostPopularCards";
import styled from "@emotion/styled";
import { ClearedStyleRouterLink } from "@modules/Components";
import { useDispatch, useSelector } from "react-redux";
import { initAppBrowser } from "@actions/initApp";
import { getBuyMerchants } from "@actions/buyMerchants";

const LostGrid = styled.main`
  display: grid;
  grid-template-columns: 1fr 1.1fr;
  padding-top: 105px;

  @media (max-width: 900px) {
    display: block;
  }
  @media (max-width: 900px) {
    padding-top: 80px;
  }
  @media (max-width: 350px) {
    padding-top: 64px;
  }
`;

const ImageContainer = styled.section`
  justify-self: center;

  @media (max-width: 900px) {
    display: flex;
    justify-content: center;
  }
`;

const Image = styled.img`
  @media (max-width: 900px) {
    width: 196px;
    height: auto;
    align-self: flex-start;
  }
  @media (max-width: 500px) {
    width: 178px;
  }
  @media (max-width: 350px) {
    width: 150px;
  }
`;

const OopsP = styled.p`
  font-size: 18px;
  line-height: 2;
  color: #777984;

  @media (max-width: 500px) {
    line-height: 1.7;
  }
`;

const Oops = styled.section`
  padding-top: 54px;

  @media (max-width: 900px) {
    text-align: center;
  }
  @media (max-width: 900px) {
    padding-top: 48px;
  }
  @media (max-width: 380px) {
    padding-top: 40px;
  }
`;

const OopsHeader = styled.h1`
  font-size: 50px;
  margin-bottom: 45px;
  color: #1f2021;

  @media (max-width: 1840px) {
    font-size: 47px;
  }
  @media (max-width: 1730px) {
    font-size: 43px;
  }
  @media (max-width: 500px) {
    font-size: 36px;
    margin-bottom: 35px;
  }
  @media (max-width: 380px) {
    font-size: 30px;
  }
`;

const CardsContainer = styled.div`
  position: relative;
  padding-top: 100px;
  padding-bottom: 100px;

  @media (max-width: 500px) {
    padding-top: 70px;
    padding-bottom: 80px;
  }
`;

const TopContainer = styled.div`
  border-radius: 0px 0px 30px 30px;
  background-color: #ffffff;
  z-index: 9;
  position: relative;
  padding-bottom: 68px;

  @media (max-width: 500px) {
    padding-bottom: 40px;
  }
`;

const InnerContainerImageGradient = styled.div`
  background-image: linear-gradient(
    to bottom,
    rgba(34, 176, 252, 0.071),
    rgba(34, 176, 252, 0.01)
  );
  background-repeat: no-repeat;
  mix-blend-mode: luminosity;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0.7;
  pointer-events: none;
  height: 100%;
`;

const LinkSpan = styled.span`
  color: #12b2ea;
`;

const NotFound = ({ staticContext = {} }) => {
  const dispatch = useDispatch();
  const merchantsBuy = useSelector(state => state.merchantsBuy);

  useEffect(() => {
    dispatch(initAppBrowser(["messages", "buy"]));
    if (Object.keys(merchantsBuy).length < 4) {
      dispatch(getBuyMerchants());
    }
  }, []);
  staticContext.notFound = true;
  return (
    <div className="mar-t-125">
      <Helmet title={`Page Not Found`} />
      <InnerContainerImageGradient />
      <TopContainer>
        <LostGrid className="main-container-page-width">
          <ImageContainer>
            <Image
              src="https://cdn.cardcash.com/website/ui/elements/empty-cage.png"
              alt=""
            />
          </ImageContainer>
          <Oops>
            <OopsHeader>
              Sorry, we couldn’t find the page you’re looking for.
            </OopsHeader>
            <OopsP>
              Try searching or just head back to our&nbsp;
              <LinkSpan>
                <ClearedStyleRouterLink to="/">homepage</ClearedStyleRouterLink>
              </LinkSpan>
              .
            </OopsP>
          </Oops>
        </LostGrid>
      </TopContainer>
      <CardsContainer>
        <MostPopularCards
          title="Save with our top deals"
          classFromParent={"not-found-page-popular-cards"}
        />
      </CardsContainer>
    </div>
  );
};

export default NotFound;
